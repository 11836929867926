.container {
    background: #2C3333;
    font-family: sans-serif;
    margin: auto;
}

.main_contain {
    padding: 50px 0;
    color: black;
}


@media only screen and (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media only screen and (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media only screen and (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}