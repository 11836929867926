.about_container {
  padding: 40px 20px;
  background-color: #222831;
  color: #fff !important;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  margin: 20px;
  text-align: center;
}

.about_container h2 {
  font-family: 'Poppins', sans-serif;
  font-size: 2.5em;
  color: #e2e2e2;
  text-align: center;
  margin-bottom: 20px;
}

.about_container h3 {
  font-size: 18px;
}

.about_container p {
  font-family: 'Poppins', sans-serif;
  font-size: 1.1em;
  color: #898686;
  line-height: 1.6;
  margin-bottom: 20px;
}