.contact_container {
  padding: 40px 20px;
  background-color: #222831;
  color: #fff !important;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  margin: 20px;
  text-align: center;
}


.contact_container h2 {
  font-family: 'Poppins', sans-serif;
  font-size: 2.5em;
  color: #e2e2e2;
  text-align: center;
  margin-bottom: 20px;
}

.contact_container h3 {
  font-size: 18px;
  color: #e2e2e2
}

.contact_container p {
  font-family: 'Poppins', sans-serif;
  font-size: 1.1em;
  color: #898686;
  line-height: 1.6;
  margin-bottom: 20px;
}

.contact_container a {
  font-family: 'Poppins', sans-serif;
  color: #898686;
  margin-bottom: 20px;
  text-transform: lowercase;
}

.contact_container a:hover {
  color: #e2e2e2;
}