.game_content iframe{
  height: 500px;
  width: 100%;
}

.loader {
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader img {
  width:120px;
}

.top {
  margin-top: 5rem;
}