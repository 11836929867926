body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: #2C3333
}

h1, h2, h3, h4, h5, h6 {
  letter-spacing: 0;
  font-weight: normal;
  position: relative;
  padding: 0 0 10px 0;
  font-weight: normal;
  line-height: normal;
  color: #111111;
  margin: 0;
}

p {
  font-weight: 300;
  margin-top: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
}